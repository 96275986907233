.react-select-input {
  /* Not really how we should do this - you should pass a custom style function (see ReactSelectInput in envoc-core) to react select, but that's not exposed via our forms API so this hack will suffice */
  @apply rounded-md shadow-sm mt-1;
  > div {
    &:first-of-type {
      /* This is the 'control' that wraps the 'value container' */

      @apply rounded-md border-gray-300 ring-indigo-600 ring-offset-0 focus-within:ring-1 focus-within:border-indigo-600;
      /* Required exact size because of issues with the input height not matching other form controls and padding already being handled by react-select in a way that was hard to adjust */
      height: 42px;

      /* disabled style is wrong - but that's really hard to do without dealing with the issues above */
    }

    svg {
      @apply cursor-pointer;
    }
  }

  /* Note: the '+' operator is the 'Adjacent sibling combinator' */
  > span + div + div {
    &:not(:last-child) {
      /* This is the pop-over for react select */
      @apply rounded-md p-2;
      > div {
        @apply flex flex-col gap-1;
        > div {
          @apply rounded-md;
        }
      }
    }
  }
  input {
    @apply !ring-0;
  }
}
