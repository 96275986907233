.basic-table {
  @apply bg-white shadow relative;
  th {
    @apply top-0 sm:-top-4 left-0 text-left bg-gray-50 text-gray-500 px-6 py-3 text-xs font-medium tracking-wider uppercase border-b;
    &.pinned-column {
      @apply bg-gray-50;
    }
    &.can-sort {
      @apply cursor-pointer hover:text-gray-900 hover:bg-gray-100;
    }
    &.can-sort::before {
      /* note: https://heroicons.com/#switch-vertical and grey-300 is used here converted to hsl for fill - see: https://yoksel.github.io/url-encoder/ */
      @apply top-1/2 -mt-2 absolute bg-no-repeat bg-center w-4 h-4 z-0 opacity-100 transition-transform -ml-5 pointer-events-none -rotate-180;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='hsl(216, 12%, 84%)'%3E%3Cpath d='M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z' /%3E%3C/svg%3E");
    }
    &.sort-asc::before {
      @apply opacity-100 rotate-0;
      /* note: https://heroicons.com/#arrow-sm-up and grey-400 is used here converted to hsl for fill - see: https://yoksel.github.io/url-encoder/ */
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='hsl(218, 11%, 65%)'%3E%3Cpath fill-rule='evenodd' d='M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
    }
    &.sort-desc::before {
      /* note: https://heroicons.com/#arrow-sm-down and grey-400 is used here converted to hsl for fill - see: https://yoksel.github.io/url-encoder/ */
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='hsl(218, 11%, 65%)'%3E%3Cpath fill-rule='evenodd' d='M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
      @apply opacity-100 rotate-180;
    }
  }

  &.loading {
    @apply brightness-95;
  }

  td {
    @apply !animate-none;
  }

  .loading-row {
    @apply !animate-none;
  }

  .no-data-row {
    @apply !animate-none;
  }

  .cell {
    @apply px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900;
  }

  tr:nth-child(odd) {
    @apply bg-white;
  }

  tr:nth-child(even) {
    @apply bg-gray-50;
  }

  .pinned-column {
    @apply sm:-left-4;
    background-color: inherit;
  }

  .cell.date-time-zone-cell {
    .date-part {
      padding-right: 5px;
    }
  }
}

.table-filter-input {
  @apply rounded-md border-gray-300  ring-indigo-500 ring-offset-0;
  @apply hover:border-indigo-300;
  @apply focus:ring-1 focus:border-indigo-500;
}

.table-paging {
  @apply flex self-center justify-center items-baseline;
  .paging {
    @apply flex-1 flex justify-center flex-wrap items-baseline gap-2;
    .page-number {
      @apply flex justify-center flex-nowrap items-baseline gap-2;
    }

    .page-size {
      @apply flex-1 min-w-fit;
      select {
        @apply w-full rounded-md border-gray-300 focus:border-indigo-500 ring-indigo-500 ring-offset-0 focus:ring-1;
      }
    }

    .page-number-input {
      @apply flex w-16 rounded-md border-gray-300 focus:border-indigo-500 ring-indigo-500 ring-offset-0 focus:ring-1;
    }
  }

  button {
    /* should be similar to ButtonLink */
    @apply flex justify-center px-4 py-2 text-sm font-medium border-0 rounded-md shadow-sm;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
    @apply disabled:cursor-not-allowed;

    @apply shadow-none;
    @apply text-blue-500 bg-transparent;
    @apply hover:bg-blue-50;
    @apply disabled:text-gray-500 disabled:bg-transparent;
  }
  .previous {
    @apply mr-2 ml-auto;
  }
  .next {
    @apply ml-2 mr-auto;
  }
}

.printable.basic-table {
  break-after: always;
  th {
    @apply relative top-auto left-auto;
  }
}
