.form-group {
  @apply mt-4;
  label {
    @apply block text-sm font-medium text-gray-700;
  }

  .form-control {
    @apply block mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 w-full;

    &.is-invalid {
      @apply border-red-300 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-red-900;
    }

    &:disabled {
      @apply bg-gray-50 bg-no-repeat bg-right-2 bg-5 cursor-not-allowed;

      /* note: https://heroicons.com/#lock-closed and grey-400 is used here converted to hsl for fill - see: https://yoksel.github.io/url-encoder/ */
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='hsl(218, 11%, 65%)'%3E%3Cpath fill-rule='evenodd' d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
    }
  }

  .invalid-feedback {
    @apply mt-1 text-sm text-red-600;
  }

  .file-input {
    @apply flex flex-col gap-1 my-1;
    .custom-file-label {
      @apply text-sm font-light px-3 py-1;
    }
  }

  > textarea {
    @apply disabled:resize-none;
  }
}

form {
  /* technically part of bootstrap, but only used in forms */
  small.form-text.text-muted {
    @apply text-gray-400 pl-2 text-xs mt-0.5 block;
  }
}

.field-array-card {
  @apply mb-4 rounded p-2 sm:p-4 border-2 border-gray-300;

  .fa-w-14 {
    @apply !h-5 !w-5;
  }

  .card-header {
    @apply flex flex-row flex-wrap items-baseline py-2 px-1;
    .card-header {
      @apply mb-3 mr-auto font-semibold;
    }
    .card-action-header-actions {
      @apply flex gap-1;
    }
  }

  .removed {
    @apply !hidden;
  }

  .field-array-item {
    @apply border-t-2 mb-3 border-gray-300 flex;
    .component {
      @apply w-11/12;
    }
    .remove-array-item {
      @apply flex flex-1 justify-center self-center;

      button {
        @apply text-red-300 hover:!bg-transparent hover:text-red-500 active:!ring-red-300 focus:!ring-red-300;
      }
    }
  }
}

.custom-checkbox {
  @apply flex items-center mt-4;
  input {
    @apply rounded h-4 w-4 border-gray-300 mr-3 cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-100;
  }
  label {
    @apply block text-sm font-medium text-gray-700 select-none;
  }
}

.confirm-delete-form {
  @apply flex flex-row flex-wrap justify-center gap-4;
  h3 {
    @apply w-full text-sm text-gray-700;
  }
  button {
    @apply flex-shrink w-24;
  }
}

.react-dropzone {
  @apply mt-1;
  aside {
    @apply text-sm font-light;
  }

  & > div {
    @apply flex flex-col h-24 p-6 cursor-pointer justify-center items-center text-gray-400 border-gray-200 border-4 border-dotted rounded-md;
  }

  &:not(.disabled) > div {
    @apply hover:bg-gray-50 hover:border-gray-300 hover:text-gray-500;
  }
  &.disabled > div {
    @apply cursor-not-allowed;
  }

  .list-group > .success {
    @apply text-indigo-400;
  }

  .list-group > .warning {
    @apply text-red-600;
  }
}
